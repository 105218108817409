<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="less">
  body,html{
    height: 100%;
    width: 100%;
  }
  #app{
    background: url("https://djmaftersales.oss-cn-shenzhen.aliyuncs.com/inquiryCard/background.png") no-repeat center center;
    background-size: cover;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
  }
  .publicBtn{
    width:100%;
    text-align: center;
    color:#fff;
    font-size: 1.067rem;
    height:3rem;
    line-height: 3rem;
    border-radius: 1.633rem;
    background: linear-gradient(57deg, rgba(112,186,255,0.27), rgba(128,141,239,0.27));
    border:0.133rem #65B0F0 solid;
    box-shadow: 0px 0px 10px #487092;
  }
  .publiczhezao{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: rgba(0,0,0,0.7);
    z-index: 900;
  }
</style>
