import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const router = new VueRouter({
    routes:[
        {
            name:'cardNameEdit',
            path:'/',
            component:()=>import('../views/cardNameEdit.vue'),
            meta:{
                title:'编辑问诊卡名称'
            }
        },
        {
            name:'editInquiry',
            path:'/editInquiry',
            component:()=>import('../views/editInquiry.vue'),
            meta:{
                title:'编辑问诊'
            }
        },
        {
            name:'topicClassEdit',
            path:'/topicClassEdit',
            component:()=>import('../views/topicClassEdit.vue'),
            meta:{
                title:'题目分类编辑'
            }
        },
        {
            name:'addTopicClass',
            path:'/addTopicClass',
            component:()=>import('../views/addTopicClass.vue'),
            meta:{
                title:'编辑问诊'
            }
        },
        {
            name:'addSingleChoice',
            path:'/addSingleChoice',
            component:()=>import('../views/addSingleChoice.vue'),
            meta:{
                title:'单选题'
            }
        },
        {
            name:'addMultipleChoice',
            path:'/addMultipleChoice',
            component:()=>import('../views/addMultipleChoice.vue'),
            meta:{
                title:'多选题'
            }
        },
        {
            name:'addGapFilling',
            path:'/addGapFilling',
            component:()=>import('../views/addGapFilling.vue'),
            meta:{
                title:'填空题'
            }
        },
        {
            name:'inquiryCard',
            path:'/inquiryCard',
            component:()=>import('../views/inquiryCard.vue'),
            meta:{
                title:'问诊卡默认模版'
            }
        },
        {
            name:'photoInquiryCard',
            path:'/photoInquiryCard',
            component:()=>import('../views/photoInquiryCard.vue'),
            meta:{
                title:'拍照页问诊卡'
            }
        },
        {
            name:'selectThisMode',
            path:'/selectThisMode',
            component:()=>import('../views/selectThisMode.vue'),
            meta:{
                title:'使用此模版成功'
            }
        },
        {
            name:'newCardSuccess',
            path:'/newCardSuccess',
            component:()=>import('../views/newCardSuccess.vue'),
            meta:{
                title:'自定义问诊卡发布成功'
            }
        },
        {
            name:'userSubmitSuccess',
            path:'/userSubmitSuccess',
            component:()=>import('../views/userSubmitSuccess.vue'),
            meta:{
                title:'用户填写问诊卡保存成功'
            }
        },
        {
            name:'goBackApp',
            path:'/goBackApp',
            component:()=>import('../views/goBackApp.vue'),
            meta:{
                title:'创建问诊卡页面返回APP'
            }
        },
        {
            name:'showTemplateCard',
            path:'/showTemplateCard',
            component:()=>import('../views/showTemplateCard.vue'),
            meta:{
                title:'默认模版'
            }
        },
    ]
})
export default router
