import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import axios from "axios";
import { Button,Col,Row,Field,Notify,Dialog} from 'vant';
import {conversion} from './filters/filters.js'
Vue.filter('conversion', conversion);

Vue.use(Button);
Vue.use(Col);
Vue.use(Row);
Vue.use(Field);
Vue.use(Notify);
Vue.use(Dialog);

Vue.config.productionTip = false
import './style/reset.less';



router.beforeEach((to,from,next) =>{
  if(to.meta.title){
    document.title = to.meta.title
  }
  next();
})


new Vue({
  router,
  axios,
  render: h => h(App),
}).$mount('#app')
